import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PublishIcon from "@material-ui/icons/Publish";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link as LinkTo } from "react-router-dom";
import styles from "./YourScoreLeaderboard.module.scss";

const YourScoreLeaderboard = ({ number, score }) => {
    return (
        <React.Fragment>
            <Typography variant='h5' component='div' className={styles.score}>
                YOUR SCORE
            </Typography>
            <LinkTo to='/submit'>
                <Grid
                    container
                    alignItems='center'
                    className={styles.k42_border_container}
                >
                    <Grid item xs={3} className={styles.k42_border_right}>
                        <Grid item xs={12}>
                            <PublishIcon
                                fontSize='large'
                                titleAccess='Share your score'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' component='p'>
                                Share your score
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <LinearProgress variant='determinate' value={number} />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            align='right'
                            variant='h5'
                            component='p'
                            className={styles.k42_right_padding}
                        >
                            {score}
                        </Typography>
                    </Grid>
                </Grid>
            </LinkTo>
        </React.Fragment>
    );
};

export default YourScoreLeaderboard;

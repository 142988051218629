import React from "react";
import { Typography } from "@material-ui/core";
import HeaderBarBack from "../HeaderBarBack";
import Container from "@material-ui/core/Container";
import styles from "./Competition.module.scss";

class Competition extends React.Component {
    render() {
        return (
            <Container className={styles.container}>
                <HeaderBarBack name='Competition Terms and Conditions' />
                <Typography>
                    <ol>
                        <li>
                            <strong>Participant Eligibility :</strong> This
                            competition is open only to all participants over
                            the legal age as mandated by each participant’s
                            local country and state regulations and excludes
                            anyone who is connected with the administration of
                            this promotion.
                        </li>
                        <li>
                            <strong>Competition Rules :</strong> Entry is
                            through playing the MYQ game only. No purchase
                            necessary to enter the competition. If you are,
                            without limitation, playing a MyQ game online, you
                            may also be charged your standard mobile operator’s
                            Internet rates for the time you are connected
                            through your mobile handset. You will be solely
                            responsible for any mobile operator charges or
                            otherwise.
                        </li>
                        <li>
                            <strong>Competition Period :</strong> 12.12. 2019 –
                            30.1.2020&nbsp;
                        </li>
                        <li>
                            <strong>Winner Selection :</strong>The winners are
                            selected based on their ranking on the leaderboard.
                            Ranks are determined by the game’s high score (of
                            the first user who obtained the score). In the event
                            that multiple users obtained the same game score,
                            the user who obtained the high score earlier would
                            rank higher than the others. There are prizes to be
                            won after finished the competition (30.1.2020)
                        </li>
                    </ol>
                    <ul>
                        <li>1-3th Prize :</li>
                        <p>[Voucher 100 EURO in Amazon e-shop].</p>
                        <li>4-10th Prize :</li>
                        <p>[Voucher 50 EURO in Amazon e-shop].</p>
                        <li>11-20th Prize :</li>
                        <p>[Voucher 25 EURO in Amazon e-shop].</p>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                        Only one prize is allowed per user. The prize(s) cannot
                        be transferred, sold or exchanged. There is no cash
                        alternative.
                    </p>
                    <ol>
                        <li>
                            <strong>Competition Entries :&nbsp;</strong>Only one
                            entry allowed per person. Multiple entries through
                            multiple accounts will be disqualified at the
                            discretion of the Promoter.
                        </li>
                        <li>
                            <strong>Winners Notification :&nbsp;</strong>The
                            winners will be notified via email through the email
                            id they logged in with for the competition - this is
                            known as the 'winner notification message' and it
                            will be sent within 30 working days after the
                            competition ends. Promoter will not be liable for
                            any failure or delay of message delivery by your
                            email platform. If winners provide invalid email
                            addresses or do not provide their email addresses,
                            they will automatically be disqualified and the
                            Promoter reserves the right to award prizes to
                            substitute winners.
                        </li>
                        <li>
                            <strong>Prize Collection :&nbsp;</strong>Winners
                            will be send their prizes via e-mail.
                        </li>
                        <li>
                            <strong>Prize Redemption :&nbsp;</strong>After
                            winners have been sent the prize. Add any terms
                            about how they are responsible for using the prize
                            and it’s not the MyQ responsibility.
                        </li>
                        <li>
                            <strong>Winner Verification :&nbsp;</strong>The
                            Promoter reserves the right to verify the
                            eligibility of any Participant and may require such
                            information as it considers reasonably necessary for
                            the purpose of verifying the eligibility of a
                            Participant. Prizes may be withheld, until the
                            Promoter is reasonably satisfied with the
                            eligibility verification.
                        </li>
                        <li>
                            <strong>Prize Substitution :</strong>The Promoter
                            reserves the right to substitute the prize for one
                            of equal or greater value in the event of
                            unavailability due to circumstances beyond the
                            Promoter’s control.
                        </li>
                        <li>
                            <strong>Winner Disqualification :</strong>The
                            Promoter reserves the right to disqualify from the
                            event any entrant suspected of fraud or cheating
                            including, without limitation, through the
                            manipulation of codes, multiple generated entries,
                            or otherwise fraudulently falsifying data or acting
                            fraudulently or dishonestly in the opinion of the
                            Promoter.
                        </li>
                        <li>
                            The Promoter will not be liable for system failures,
                            network errors, the Branded Mini-Game being
                            unavailable, hacks on the system or personal phone
                            or application issues.
                        </li>
                        <li>
                            If there is any dispute about an entry or any other
                            aspect of the competition, this will be decided by
                            the Promoter. The Promoter’s decision is final in
                            every situation (including any not covered by these
                            terms and conditions) and no correspondence will be
                            entered into.
                        </li>
                        <li>
                            The Promoter may collect personal information from
                            Entrants. Please see the Promoter’s Privacy Policy
                            for more details on how Entrants personal
                            information will be used.
                        </li>
                        <li>
                            By entering this competition, you agree that the
                            Promoter may use your name, photo and any
                            testimonial you have provided to the Promoter, for
                            promotional purposes, including, without limitation,
                            on its websites, social media, within its magazines
                            or within promotional emails.
                        </li>
                        <li>
                            The Promoter reserves the right at its absolute
                            discretion to cancel the competition without prior
                            notice in the event of circumstances arising beyond
                            its control that make it necessary to do so.
                        </li>
                        <li>
                            Entry to the competition shall constitute your
                            acceptance of these terms and conditions and your
                            agreement to be bound by them. If these terms and
                            conditions are not accepted in full, then you should
                            not enter this competition. The Promoter reserves
                            the right to amend these terms and conditions at any
                            time.
                        </li>
                        <li>
                            The competition and the terms and conditions are
                            governed by the law of Czech Republic.
                            &nbsp;PROMOTER: MyQ spol. s.r.o.
                        </li>
                    </ol>
                </Typography>
            </Container>
        );
    }
}

export default Competition;

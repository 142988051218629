import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import styles from "./ScoreOfOthers.module.scss";

const ScoreOfOthers = ({ number, score, name }) => {
    return (
        <Grid
            container
            alignItems='center'
            justify='center'
            className={styles.k42_container}
        >
            <Grid item sm={3} xs={3}>
                <Typography variant='h5' component='p' gutterBottom>
                    {name}
                </Typography>
            </Grid>
            <Grid item sm={6} xs={6}>
                <LinearProgress variant='determinate' value={number} />
            </Grid>
            <Grid item sm={3} xs={3}>
                <Typography
                    align='right'
                    variant='h5'
                    component='p'
                    className={styles.k42_right_padding}
                >
                    {score}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ScoreOfOthers;

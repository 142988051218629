const touchHandler = event => {
    var touches = event.changedTouches,
        first = touches[0],
        type = "";
    switch (event.type) {
        case "touchstart":
            type = "mousedown";
            break;
        case "touchmove":
            type = "mousemove";
            break;
        case "touchend":
            type = "mouseup";
            break;
        default:
            return;
    }

    var simulatedEvent = document.createEvent("MouseEvent");
    simulatedEvent.initMouseEvent(
        type,
        true,
        true,
        window,
        1,
        first.screenX,
        first.screenY,
        first.clientX,
        first.clientY,
        false,
        false,
        false,
        false,
        0 /*left*/,
        null
    );

    first.target.dispatchEvent(simulatedEvent);
    event.preventDefault();
};

const disableTouch = () => {
    // document.addEventListener("touchstart", touchHandler, { passive: false });
    document.addEventListener("touchmove", touchHandler, { passive: false });
    // document.addEventListener("touchend", touchHandler, { passive: false });
    // document.addEventListener("touchcancel", touchHandler, { passive: false });
};

const enableTouch = () => {
    document.removeEventListener("touchmove", touchHandler, { passive: false });
};

const checkMobile = () => {
    window.addEventListener(
        "touchstart",
        e => {
            window.isMobile = true;
        },
        { passive: false }
    );
};

export { disableTouch, enableTouch, checkMobile };

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getSession, saveMyScore } from "../../actions/results";
import { JoystickController } from "../../common/VirtualJoystick";
import { disableTouch, enableTouch } from "../../common/helpers/touchHandlers";

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = { level: 1 };
    }

    joystick;

    componentDidMount() {
        /*
        For correct device detection we need to navigate to this screen to
        check touch events, in case of refresh navigate to intro screen
         */
        if (this.props.history.action === "POP") {
            this.props.history.push("/");
        }

        this.props.getSession();
        window.Delta();
        Stopwatch.start();

        window.addEventListener("endgame", event => {
            const myActualScore = event.detail;
            Stopwatch.stop();
            this.props.saveMyScore(myActualScore, Stopwatch.duration());
            this.props.history.push("/submit");
        });

        window.addEventListener("changeLevel", event => {
            this.setState({ level: event.detail });
        });

        if (window.isMobile) {
            disableTouch();
            this.initJoystick();
            window.sounds.toggleMute(true);
        }
    }

    initJoystick = () => {
        this.joystick = new JoystickController("delta", true, "#000000");

        this.joystick.onRight(() => {
            window.player.movingRight = true;
            window.player.movingLeft = false;
            window.player.movingUp = false;
            window.player.movingDown = false;
            window.player.firing = true;
        });

        this.joystick.onLeft(() => {
            window.player.movingRight = false;
            window.player.movingLeft = true;
            window.player.movingUp = false;
            window.player.movingDown = false;
            window.player.firing = true;
        });

        this.joystick.onUp(() => {
            window.player.movingRight = false;
            window.player.movingLeft = false;
            window.player.movingUp = true;
            window.player.movingDown = false;
            window.player.firing = true;
        });

        this.joystick.onDown(() => {
            window.player.movingRight = false;
            window.player.movingLeft = false;
            window.player.movingUp = false;
            window.player.movingDown = true;
            window.player.firing = true;
        });

        this.joystick.onHold(() => {
            window.player.movingRight = false;
            window.player.movingLeft = false;
            window.player.movingUp = false;
            window.player.movingDown = false;
        });

        this.joystick.onRelease(() => {
            window.player.movingRight = false;
            window.player.movingLeft = false;
            window.player.movingUp = false;
            window.player.movingDown = false;
            window.player.firing = false;
        });
    };

    componentWillUnmount = () => {
        if (this.joystick) {
            this.joystick.destroy();
        }
        enableTouch();
        window.cfg = null;
        window.sounds = null;
        window.engine = null;
        window.player = null;
        window.bullets = null;
        window.aliens = null;
        window.rocks = null;
        window.stars = null;
        window.renderer = null;
    };

    render() {
        return (
            <div id='delta'>
                <div id='scoreboard'>
                    <span className='lives'>
                        <span className='label'>LIVES:</span>
                        <img
                            className='one'
                            src={
                                process.env.PUBLIC_URL +
                                "/delta-game/images/life" +
                                this.state.level +
                                ".png"
                            }
                            style={{ width: "auto", height: "14px" }}
                            alt={"life1"}
                        />
                        <img
                            className='two'
                            src={
                                process.env.PUBLIC_URL +
                                "/delta-game/images/life" +
                                this.state.level +
                                ".png"
                            }
                            style={{ width: "auto", height: "14px" }}
                            alt={"life2"}
                        />
                        <img
                            className='three'
                            src={
                                process.env.PUBLIC_URL +
                                "/delta-game/images/life" +
                                this.state.level +
                                ".png"
                            }
                            style={{ width: "auto", height: "14px" }}
                            alt={"life3"}
                        />
                    </span>
                    <span className='score'>
                        <span className='label'>SCORE:</span>
                        <span className='value'>00000</span>
                    </span>
                </div>
                <img
                    id='booting'
                    src={
                        process.env.PUBLIC_URL +
                        "/delta-game/images/booting.gif"
                    }
                    alt={"booting"}
                />
                <h1 id='title' style={{ display: "none" }}></h1>
                <h2 id='start' style={{ display: "none" }}></h2>
                <h1 id='prepare' style={{ display: "none" }}>
                    PLAY TO BECOME A SUPERHERO
                </h1>
                <h1 id='secondlevel' style={{ display: "none" }}>
                    Second Level – Home Office
                </h1>
                <canvas id='canvas'>
                    <div className='unsupported'>
                        Sorry, this app cannot be run because your browser does
                        not support the &lt;canvas&gt; element
                    </div>
                </canvas>
                <div id='instructions'>
                    {window.isMobile
                        ? "USE JOYSTICK TO MOVE AND SHOOT"
                        : "ARROW KEYS TO MOVE, SPACEBAR TO SHOOT"}
                </div>
                <div
                    id='sound'
                    className='on'
                    title='toggle music and fx'
                    style={{ display: "none" }}
                ></div>
            </div>
        );
    }
}

function StopWatch() {
    let startTime = null;
    let stopTime = null;
    let running = false;

    function getTime() {
        var day = new Date();
        return day.getTime();
    }
    this.start = function() {
        if (running === true) return;
        else if (startTime != null) stopTime = null;

        running = true;
        startTime = getTime();
    };

    this.stop = function() {
        if (running === false) return;

        stopTime = getTime();
        running = false;
    };

    this.duration = function() {
        if (startTime == null || stopTime == null) return "Undefined";
        else return (stopTime - startTime) / 1000;
    };
}

const Stopwatch = new StopWatch();

const mapStateToProps = state => ({
    myresult: state.myresult
});

const mapDispatchToProps = dispatch => ({
    getSession: bindActionCreators(getSession, dispatch),
    saveMyScore: bindActionCreators(saveMyScore, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Game));

import { withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Intro.module.scss";

const CustomCheck = withStyles({
    root: {
        color: "#fff"
    },
    checked: {}
})(props => <Checkbox color='default' {...props} />);

class Intro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false
        };
    }

    render() {
        return (
            <div className={`${styles.background} fixed-mobile`}>
                <img
                    alt=''
                    className={styles.printer}
                    src={`${process.env.PUBLIC_URL}/img/intro_printer.png`}
                />
                <img
                    alt=''
                    className={styles.superHero}
                    src={`${process.env.PUBLIC_URL}/img/intro_superhero.png`}
                />
                <img
                    alt=''
                    className={styles.logo}
                    src={`${process.env.PUBLIC_URL}/img/intro_logo.png`}
                />
                {/*
                <Typography
                className={styles.bannerText}
                variant='h5'
                component='p'
                >
                Competition runs between <br />
                23 Dec 2020 and 31 Jan 2021
                </Typography>
                */}
                {this.state.check ? (
                    <Link to={`/game`}>
                        <img
                            alt=''
                            className={styles.textRight}
                            src={`${process.env.PUBLIC_URL}/img/intro_start_button.png`}
                        />
                    </Link>
                ) : (
                    <img
                        alt=''
                        className={styles.textRight}
                        src={`${process.env.PUBLIC_URL}/img/intro_start_button_disabled.png`}
                    />
                )}

                <Grid item xs={12} sm={6} className={styles.checkbox}>
                    <FormControlLabel
                        control={
                            <CustomCheck
                                checked={this.state.check}
                                onChange={() =>
                                    this.setState({ check: !this.state.check })
                                }
                            />
                        }
                        label={
                            <div>
                                <span>I agree with </span>
                                <a
                                    href='https://www.myq-solution.com/en/privacy-policy'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    underline='always'
                                >
                                    Privacy Policy
                                </a>
                                {/* 
                                <span> and </span>
                                <a
                                href={`${process.env.PUBLIC_URL}/game-rules.pdf`}
                                rel='noopener noreferrer'
                                target='_blank'
                                underline='always'
                                >
                                Rules of Game
                                </a>
                                */}
                            </div>
                        }
                    />
                </Grid>
                <img
                    alt=''
                    className={styles.textBottom}
                    src={`${process.env.PUBLIC_URL}/img/intro_text_bottom.png`}
                />
            </div>
        );
    }
}

export default Intro;

import React from "react";
import styles from "./RotatePrompt.module.scss";

const RotatePrompt = () => {
    return (
        <div className={styles.promptRotate}>
            <img
                alt='MyQ Logo'
                className={styles.loginLogo}
                src={`${process.env.PUBLIC_URL}/img/myq_logo_600.png`}
            />
            <img
                alt='Rotate Icon'
                className={styles.rotateIcon}
                src={`${process.env.PUBLIC_URL}/img/rotate.png`}
            />
            <p>Please rotate your device</p>
        </div>
    );
};

export default RotatePrompt;

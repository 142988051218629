import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import styles from "./PlayAgainButton.module.scss";

const PlayAgainButton = () => {
    return (
        <Grid
            container
            alignItems='center'
            justify='center'
            className={styles.k42_container}
            spacing={2}
        >
            <Grid item md={1} sm={2} xs={2}>
                <Fab color='primary' aria-label='play again'>
                    <PlayArrowIcon fontSize='large' titleAccess='Play Again' />
                </Fab>
            </Grid>
            <Grid item md={2} sm={3} xs={3}>
                <Typography variant='h4' className={styles.k42_cursor_pointer}>
                    Play Again
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PlayAgainButton;

import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import styles from "./YourScore.module.scss";

const YourScore = ({ number, score }) => {
    return (
        <Grid container alignItems='center'>
            <Grid item sm={2} xs={3}>
                <Typography
                    align='left'
                    className={styles.k42_uppercase}
                    variant='h5'
                    component='p'
                    gutterBottom
                >
                    Your Score
                </Typography>
            </Grid>
            <Grid item sm={6} xs={6}>
                <LinearProgress variant='determinate' value={number} />
            </Grid>
            <Grid item sm={4} xs={3}>
                <Typography
                    align='right'
                    variant='h5'
                    component='p'
                    className={styles.k42_right_padding}
                >
                    {score}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default YourScore;

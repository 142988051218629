import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import styles from "./HeaderBarBack.module.scss";
import { withRouter } from "react-router-dom";

const HeaderBarBack = ({ name, history }) => {
    return (
        <Grid
            container
            alignItems='center'
            justify='space-between'
            className={styles.k42_container_headerbar}
        >
            <Grid item xs={6}>
                <Grid container alignItems='center'>
                    <Grid
                        item
                        sm={2}
                        xs={2}
                        onClick={() => history.goBack()}
                        style={{ cursor: "pointer" }}
                    >
                        <ArrowBackIosIcon
                            titleAccess='Arrow Back'
                            fontSize='large'
                        />
                    </Grid>
                    <Grid item sm={10} xs={10}>
                        <Typography variant='h3'>{name}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6} className={styles.k42_logo_myq_container}>
                <img
                    className={styles.k42_logo_myq}
                    src='../img/myq_logo_600.png'
                    alt='MyQ Logo'
                />
            </Grid>
        </Grid>
    );
};

export default withRouter(HeaderBarBack);

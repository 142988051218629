import axios from "axios";
import * as ActionTypes from "../constants/ActionTypes";

export const API = "API";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_RESULTS = process.env.REACT_APP_API_RESULTS;
const API_SUBMIT_RESULT = process.env.REACT_APP_API_SUBMIT_RESULT;

export const getSession = async (next, successType) => {
    return next({ type: successType, payload: "" });
};

export const getResults = async (next, successType) => {
    const res = await axios.get(`${API_ENDPOINT}${API_RESULTS}`);
    const results = res.data;
    return next({ type: successType, payload: results });
};

export const submitResults = async (next, successType, results, token) => {
    await axios.post(`${API_ENDPOINT}${API_SUBMIT_RESULT}`, {
        ...results,
        token: ""
    });
    return next({ type: successType });
};

export default store => next => async action => {
    const api = action[API];

    if (typeof api === "undefined") {
        return next(action);
    }

    const { types, results } = api;
    const [requestType, successType, errorType] = types;
    try {
        switch (requestType) {
            case ActionTypes.GET_SESSION_REQUEST:
                next({ type: requestType });
                await getSession(next, successType);
                break;
            case ActionTypes.GET_RESULTS_REQUEST:
                next({ type: requestType });
                await getResults(next, successType);
                break;
            case ActionTypes.SUBMIT_RESULTS_REQUEST:
                next({ type: requestType, results });
                await submitResults(
                    next,
                    successType,
                    results,
                    store.getState().token
                );
                break;
            default:
                return next(action);
        }
    } catch (e) {
        next({ type: errorType, error: e.message });
    }
};

export const GET_SESSION_REQUEST = "GET_SESSION_REQUEST";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";
export const GET_SESSION_ERROR = "GET_SESSION_ERROR";

export const SUBMIT_RESULTS_REQUEST = "SUBMIT_RESULTS_REQUEST";
export const SUBMIT_RESULTS_SUCCESS = "SUBMIT_RESULTS_SUCCESS";
export const SUBMIT_RESULTS_ERROR = "SUBMIT_RESULTS_ERROR";

export const SUBMIT_MYRESULT = "SUBMIT_MYRESULT";

export const GET_RESULTS_REQUEST = "GET_RESULTS_REQUEST";
export const GET_RESULTS_SUCCESS = "GET_RESULTS_SUCCESS";
export const GET_RESULTS_ERROR = "GET_RESULTS_ERROR";

export const SAVE_MY_SCORE = "SAVE_MY_SCORE";

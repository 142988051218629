import VirtualJoystick from "./VirtualJoystick";

class JoystickController {
    listeners = {};
    intervalId;

    constructor(containerId, stationaryBase, strokeColor) {
        this.joystick = new VirtualJoystick({
            stationaryBase: stationaryBase,
            strokeStyle: strokeColor,
            container: document.getElementById(containerId),
            mouseSupport: false
        });

        this.joystick.addEventListener("touchEnd", () => {
            if (this.listeners.onRelease) this.listeners.onRelease();
        });

        this.intervalId = setInterval(() => {
            if (this.joystick.up()) {
                if (this.listeners.onUp) this.listeners.onUp();
                return;
            }
            if (this.joystick.down()) {
                if (this.listeners.onDown) this.listeners.onDown();
                return;
            }
            if (this.joystick.right()) {
                if (this.listeners.onRight) this.listeners.onRight();
                return;
            }
            if (this.joystick.left()) {
                if (this.listeners.onLeft) this.listeners.onLeft();
                return;
            }
            if (this.listeners.onHold) this.listeners.onHold();
        }, (1 / 10) * 1000);
    }

    onRight = callback => {
        this.listeners.onRight = callback;
    };

    onLeft = callback => {
        this.listeners.onLeft = callback;
    };

    onUp = callback => {
        this.listeners.onUp = callback;
    };

    onDown = callback => {
        this.listeners.onDown = callback;
    };

    onRelease = callback => {
        this.listeners.onRelease = callback;
    };

    onHold = callback => {
        this.listeners.onHold = callback;
    };

    destroy = () => {
        clearInterval(this.intervalId);
    };
}

export default JoystickController;

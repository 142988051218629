import * as ActionTypes from "../constants/ActionTypes";

export const defaultState = () => ({
    session: false,
    token: localStorage.getItem("token"),
    myresult: [{ score: 0, myScore: true, submitted: false, time: 0 }],
    results: []
});

export function mainReducer(state = defaultState(), action) {
    switch (action.type) {
        case ActionTypes.GET_SESSION_SUCCESS:
            return {
                ...state,
                token: action.payload,
                session: true
            };
        case ActionTypes.SUBMIT_RESULTS_SUCCESS:
            return {
                ...state,
                session: false,
                token: null
            };
        case ActionTypes.SUBMIT_MYRESULT:
            return {
                ...state,
                myresult: [
                    {
                        fullName: action.myresult.fullName,
                        score: action.myresult.score,
                        numberScore: action.myresult.numberScore,
                        myScore: true,
                        submitted: true,
                        time: action.myresult.time
                    }
                ]
            };
        case ActionTypes.GET_RESULTS_SUCCESS:
            return {
                ...state,
                results: action.payload
            };
        case ActionTypes.SAVE_MY_SCORE:
            return {
                ...state,
                myresult: [
                    {
                        score: action.score.score,
                        myScore: true,
                        submitted: false,
                        time: action.time
                    }
                ]
            };
        default:
            return state;
    }
}

import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import resultMiddleware from "../middleware/results";

import { mainReducer, defaultState } from "../reducers";

export default () => {
    return createStore(
        mainReducer,
        defaultState(),
        composeWithDevTools(applyMiddleware(thunk, resultMiddleware))
    );
};

// function for counting score for linear progress bar
export const countScore = (myresults, allresults) => {
    const sumOfResults = myresults.concat(allresults);
    const sortedScore = sumOfResults.sort((a, b) => {
        return b.score - a.score; //to change order of sort, change B for A or A for B
    });

    const highestScore = sortedScore[0]["score"]; //select the first array and first item which is the score

    const sortedScoreWithNumberScore = sortedScore.map(oneResult => {
        let numberScore = Math.round((oneResult.score / highestScore) * 100);
        return {
            fullName: oneResult.fullName,
            score: oneResult.score,
            numberScore: numberScore,
            myScore: oneResult.myScore,
            submitted: oneResult.submitted
        };
    });

    return sortedScoreWithNumberScore;
};

import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import HeaderBarBack from "../../components/HeaderBarBack";
import YourScore from "../../components/YourScore";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    submitResults,
    submitMyResult,
    getResults
} from "../../actions/results";
import { countScore } from "../../common/helpers";
import styles from "./Submit.module.scss";
import Container from "@material-ui/core/Container";
import { RadioGroup, Radio, withStyles } from "@material-ui/core";

const CustomRadio = withStyles({
    root: {
        color: "#fff"
    },
    checked: {}
})(props => <Radio color='default' {...props} />);

class Submit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullname: "",
            company: "",
            email: "",
            checkgdpr: false,
            competition: false,
            numberScore: 0,
            referral: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.getResults();
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    handleCheck = event => {
        const target = event.target;
        const checked = target.checked;
        const nameid = target.id;
        this.setState({
            [nameid]: checked
        });
    };

    handleSubmit = event => {
        event.preventDefault();
        const myresults = this.props.myresult.find(item => {
            return item.myScore;
        });

        const data = {
            score: myresults.score,
            fullName: this.state.fullname,
            company: this.state.company,
            email: this.state.email,
            referral: this.state.referral,
            time: myresults.time
        };

        const myresultdata = {
            fullName: this.state.fullname,
            score: myresults.score,
            numberScore: this.state.numberScore,
            time: myresults.time
        };

        this.props.submitMyResult(myresultdata);
        this.props.submitResults(data);
        this.props.history.push("/leaderboard");
    };

    render() {
        const myResult = this.props.myresult;
        const allResults = this.props.results;

        const scoreNumbers = countScore(myResult, allResults);

        const yourScore = scoreNumbers.find(item => {
            return item.myScore;
        });

        return (
            <Container className={styles.container}>
                <HeaderBarBack name='Submit Your Score' />

                <div className={styles.k42_yourscore_container_margin}>
                    <YourScore
                        number={yourScore.numberScore}
                        score={yourScore.score}
                    />
                </div>

                <form onSubmit={this.handleSubmit}>
                    <Grid
                        container
                        alignItems='center'
                        className={styles.k42_margin_inputs}
                    >
                        <Grid item xs={12} sm={2}>
                            <FormLabel>
                                <InputLabel
                                    htmlFor='fullname'
                                    className={styles.k42_margin_label_xs}
                                >
                                    Full Name
                                </InputLabel>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id='fullname'
                                    name='fullname'
                                    type='text'
                                    value={this.state.fullname}
                                    onChange={this.handleInputChange}
                                    placeholder='John Doe'
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <FormLabel>
                                <InputLabel
                                    htmlFor='company'
                                    className={styles.k42_margin_label_xs}
                                >
                                    Company
                                </InputLabel>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id='company'
                                    name='company'
                                    type='text'
                                    value={this.state.company}
                                    onChange={this.handleInputChange}
                                    placeholder='Company Name'
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        alignItems='center'
                        className={styles.k42_margin_inputs}
                    >
                        <Grid item xs={12} sm={2}>
                            <FormLabel>
                                <InputLabel
                                    htmlFor='email'
                                    className={styles.k42_margin_label_xs}
                                >
                                    Email
                                </InputLabel>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id='email'
                                    name='email'
                                    type='email'
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    placeholder='johndoe@gmail.com'
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='caption'>Optional</Typography>
                            <Typography>
                                Where did you hear about the game?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    row
                                    aria-label='referral'
                                    name='referral'
                                    defaultValue='top'
                                >
                                    {[
                                        "Facebook",
                                        "Instagram",
                                        "Newsletter",
                                        "Linked In"
                                    ].map((referral, key) => (
                                        <FormControlLabel
                                            key={key}
                                            value={referral}
                                            onChange={event => {
                                                this.setState({
                                                    referral: event.target.value
                                                });
                                            }}
                                            control={
                                                <CustomRadio color='primary' />
                                            }
                                            label={referral}
                                            labelPlacement='end'
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <Typography></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='checkgdpr'
                                        checked={this.state.checkgdpr}
                                        onChange={this.handleCheck}
                                        value='checked-gdpr'
                                        color='primary'
                                        required
                                    />
                                }
                                label={
                                    <div>
                                        <span>I accept </span>
                                        <a
                                            href='http://help.myq-solution.com/MyQ_Data_Processors_&_Affiliates.pdf'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            color='secondary'
                                            underline='always'
                                        >
                                            MyQ Privacy Policy
                                        </a>
                                    </div>
                                }
                            />
                        </Grid>
                    </Grid>

                    {/* <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <Typography></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='competition'
                                        checked={this.state.competition}
                                        onChange={this.handleCheck}
                                        value='checked-competition'
                                        color='primary'
                                        required
                                    />
                                }
                                label={
                                    <div>
                                        <span>I accept </span>
                                        <LinkTo
                                            to='/competition'
                                            color='secondary'
                                            underline='always'
                                        >
                                            Competition Terms and Conditions
                                        </LinkTo>
                                    </div>
                                }
                            />
                        </Grid>
                    </Grid> */}

                    <Grid
                        container
                        alignItems='center'
                        className={styles.k42_margin_inputs}
                    >
                        <Grid item xs={12}>
                            <Button
                                type='submit'
                                size='medium'
                                variant='contained'
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    results: state.results,
    myresult: state.myresult
});

const mapDispatchToProps = dispatch => ({
    submitResults: bindActionCreators(submitResults, dispatch),
    submitMyResult: bindActionCreators(submitMyResult, dispatch),
    getResults: bindActionCreators(getResults, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Submit);

import React from "react";
import { Link as LinkTo } from "react-router-dom";
import HeaderBar from "../../components/HeaderBar";
import ScoreOfOthers from "../../components/ScoreOfOthers";
import YourScoreLeaderboard from "../../components/YourScoreLeaderboard";
import ScoreLeaderboardSubmitted from "../../components/ScoreLeaderboardSubmitted";
import PlayAgainButton from "../../components/PlayAgainButton";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getResults } from "../../actions/results";
import { countScore } from "../../common/helpers";
import Container from "@material-ui/core/Container";
import styles from "./Leaderboard.module.scss";

class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    async componentDidMount() {
        this.setState({
            loading: true
        });

        await this.props.getResults();

        this.setState({
            loading: false
        });
    }

    // TODO: refaktorovat tady tu silenost
    render() {
        const SpinnerCSS = `
            position: relative;
            display: block;
            margin: 5rem auto;
        `;

        const myResult = this.props.myresult;
        const allResults = this.props.results;

        const scoreNumbers = countScore(myResult, allResults);

        const topNLeaderboard = n => {
            if (scoreNumbers.length < n)
                return outputLeaderboard(scoreNumbers.length);
            const lastOneScore = scoreNumbers.slice(n - 1, n)[0].score;
            const myScore = myResult[0].score;
            if (myScore > lastOneScore) return outputLeaderboard(n);

            return appendScore(
                scoreNumbers.filter(scoreNumber => scoreNumber.myScore)[0],
                n
            );
        };

        const appendScore = (scoreObject, n) => {
            return (
                <React.Fragment>
                    {addYourScore(scoreObject, n + 1)}
                    {allResults.length > 5 && (
                        <LinkTo color='secondary' to='/game'>
                            <PlayAgainButton />
                        </LinkTo>
                    )}
                    {outputLeaderboard(n)}
                </React.Fragment>
            );
        };

        const outputLeaderboard = n =>
            scoreNumbers.slice(0, n).map((item, index) => {
                if (item.myScore === true) return addYourScore(item, index);
                return (
                    <ScoreOfOthers
                        number={item.numberScore}
                        score={item.score}
                        name={item.fullName}
                        key={index}
                    />
                );
            });

        const addYourScore = (scoreObject, index) => {
            if (scoreObject.submitted === true) {
                return (
                    <ScoreLeaderboardSubmitted
                        number={scoreObject.numberScore}
                        score={scoreObject.score}
                        name={scoreObject.fullName}
                        key={index}
                    />
                );
            } else if (scoreObject.score === 0) {
                return null;
            }
            return (
                <YourScoreLeaderboard
                    number={scoreObject.numberScore}
                    score={scoreObject.score}
                    key={index}
                />
            );
        };

        return (
            <Container className={styles.container}>
                <HeaderBar name='Leaderboard' />

                <ClipLoader
                    css={SpinnerCSS}
                    sizeUnit={"px"}
                    size={150}
                    color={"#484848"}
                    loading={this.state.loading}
                />

                {myResult && scoreNumbers && topNLeaderboard(30)}

                {/* Play Again Button with anchor link*/}
                <div className={styles.lastButton}>
                    <LinkTo color='secondary' to='/game'>
                        <PlayAgainButton />
                    </LinkTo>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    results: state.results,
    myresult: state.myresult
});

const mapDispatchToProps = dispatch => ({
    getResults: bindActionCreators(getResults, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);

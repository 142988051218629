import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styles from "./HeaderBar.module.scss";

const HeaderBar = ({ name }) => {
    return (
        <Grid
            container
            alignItems='center'
            justify='space-between'
            className={styles.k42_container_headerbar}
        >
            <Grid item xs={6}>
                <Typography variant='h3'>{name}</Typography>
            </Grid>

            <Grid item xs={6} className={styles.k42_logo_myq_container}>
                <img
                    className={styles.k42_logo_myq}
                    src='../img/myq_logo_600.png'
                    alt='MyQ Logo'
                />
            </Grid>
        </Grid>
    );
};

export default HeaderBar;

import ReactGA from "react-ga";

const trackerGA = history => {
    return history.listen(location => {
        ReactGA.set({
            page: location.pathname + location.search
        });
        ReactGA.pageview(location.pathname + location.search);
    });
};

export default trackerGA;

//Color setup and overriding Material-UI components default setup
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
    typography: {
        fontFamily: "Ubuntu, sans-serif",
        fontSize: 12
    },
    palette: {
        primary: {
            light: "#cc1427",
            main: "#db0027",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#ffffff",
            contrastText: "#ffffff"
        }
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: "#ffffff"
            }
        },
        MuiCheckbox: {
            root: {
                color: "#ffffff"
            }
        },
        MuiOutlinedInput: {
            root: {
                "&:hover $notchedOutline": {
                    borderColor: "#ffffff"
                },
                color: "#ffffff"
            },
            notchedOutline: {
                borderColor: "#ffffff",
                borderRadius: 0
            }
        },
        MuiLinearProgress: {
            root: {
                height: 8,
                borderRadius: 20
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0
            },
            sizeLarge: {
                padding: "16px 48px",
                fontSize: "1.9375rem"
            },
            textSecondary: {
                backgroundColor: "#ffffff"
            }
        }
    }
});

export default theme;

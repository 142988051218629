import React from "react";
import Game from "../Game";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../constants";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Submit from "../Submit";
import Leaderboard from "../Leaderboard";
import Intro from "../Intro";
import Competition from "../../components/Competition";
import Privacy from "../../components/Competition";
import MediaQuery from "react-responsive";
import RotatePrompt from "../../components/RotatePrompt";
import { checkMobile } from "../../common/helpers/touchHandlers";
import ReactGA from "react-ga";
import trackerGA from "../../common/helpers/GoogleAnalytics.js";

checkMobile();

if (process.env.REACT_APP_STAGE === "prod") {
    ReactGA.initialize("UA-138421938-3");
}

const App = ({ history }) => {
    trackerGA(history);

    return (
        <ThemeProvider theme={theme}>
            <MediaQuery query='(orientation: portrait)'>
                <Redirect to='/' />
                <RotatePrompt />
            </MediaQuery>
            <MediaQuery query='(orientation: landscape)'>
                <Switch>
                    <Route exact path='/' component={Intro} />
                    <Route exact path='/game' component={Game} />
                    <Route exact path='/leaderboard' component={Leaderboard} />
                    <Route exact path='/submit' component={Submit} />
                    <Route exact path='/privacy' component={Privacy} />
                    <Route exact path='/competition' component={Competition} />
                </Switch>
            </MediaQuery>
        </ThemeProvider>
    );
};

export default withRouter(App);

import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import styles from "./ScoreLeaderboardSubmitted.module.scss";

const ScoreLeaderboardSubmitted = ({ number, score, name }) => {
    return (
        <Grid
            container
            alignItems='center'
            className={styles.k42_border_container}
        >
            <Grid item sm={3} xs={3} className={styles.k42_padding}>
                <Typography
                    variant='h5'
                    component='p'
                    className={styles.k42_left_padding}
                >
                    {name}
                </Typography>
            </Grid>
            <Grid item sm={6} xs={6}>
                <LinearProgress variant='determinate' value={number} />
            </Grid>
            <Grid item sm={3} xs={3}>
                <Typography
                    align='right'
                    variant='h5'
                    component='p'
                    className={styles.k42_right_padding}
                >
                    {score}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ScoreLeaderboardSubmitted;

import * as ActionTypes from "../constants/ActionTypes";
import { API } from "../middleware/results";

export const getSession = () => {
    return {
        type: ActionTypes.GET_SESSION_REQUEST,
        [API]: {
            types: [
                ActionTypes.GET_SESSION_REQUEST,
                ActionTypes.GET_SESSION_SUCCESS,
                ActionTypes.GET_SESSION_ERROR
            ]
        }
    };
};

export const saveMyScore = (score, time) => {
    localStorage.setItem("s", h(score.score));
    // score, token
    return {
        type: ActionTypes.SAVE_MY_SCORE,
        score,
        time
    };
};

const h = s => {
    s = s.toString();
    var ha = 0,
        i,
        chr;
    if (s.length === 0) return ha;
    for (i = 0; i < s.length; i++) {
        chr = s.charCodeAt(i);
        ha = (ha << 5) - ha + chr;
        ha |= 0;
    }
    return ha;
};

export const submitMyResult = myresult => {
    return {
        type: ActionTypes.SUBMIT_MYRESULT,
        myresult
    };
};

export const submitResults = results => {
    return {
        type: ActionTypes.SUBMIT_RESULTS_REQUEST,
        [API]: {
            results,
            types: [
                ActionTypes.SUBMIT_RESULTS_REQUEST,
                ActionTypes.SUBMIT_RESULTS_SUCCESS,
                ActionTypes.SUBMIT_RESULTS_ERROR
            ]
        }
    };
};

export const getResults = () => {
    return {
        type: ActionTypes.GET_RESULTS_REQUEST,
        [API]: {
            types: [
                ActionTypes.GET_RESULTS_REQUEST,
                ActionTypes.GET_RESULTS_SUCCESS,
                ActionTypes.GET_RESULTS_ERROR
            ]
        }
    };
};
